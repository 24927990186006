let switchers = document.querySelectorAll('.links-lang-switcher')

if (switchers) {
  switchers.forEach((switcher) => {
    let langs = switcher.querySelectorAll('.links-lang-switcher__lang')
    let wrapper = switcher.closest('LI')
    langs.forEach(function (el) {
      el.addEventListener('click', () => {
        if (!el.classList.contains('active')) {

          let activeSwitch = wrapper.querySelector('.links-lang-switcher__lang.active')
          activeSwitch.classList.remove('active')
          el.classList.add('active')
          let curLang = el.getAttribute('data-lang')
          let links = wrapper.querySelectorAll('A')
          if (links) {
            links.forEach((link) => {
              if (link.getAttribute('data-lang') == curLang) {
                link.classList.remove('hidden')
              } else {
                link.classList.add('hidden')
              }
            })
          }
        }
      })
    })
  })
}