(function () {
  // const $header = document.querySelector('.page-header');
  // const $menu = document.querySelector('.main-nav');
  // const $hamburger = document.querySelector('.burger');
  // let isMenuOpened = false;
  let disabledItem = document.querySelectorAll('.nav__primary>#menu-main-menu>li>.sub-menu>li>a');

  disabledItem.forEach((el) => {
    el.onclick = (e) => {
      e.preventDefault();
    }
  });

  // if ($hamburger) {
  //   $hamburger.addEventListener('click', function () {
  //     this.classList.toggle('opened')
  //     $menu.classList.toggle('opened');
  //     $header.classList.toggle('menu-opened');
  //     isMenuOpened = !isMenuOpened;

  //     if (isMenuOpened) {
  //       bodyScrollLock.disableBodyScroll($menu);
  //     } else {
  //       bodyScrollLock.clearAllBodyScrollLocks();
  //     }
  //   });
  // }

  // window.addEventListener('resize', debounce(function () {
  //   if (isMenuOpened && window.innerWidth >= 768) {
  //     bodyScrollLock.clearAllBodyScrollLocks();
  //   }
  // }))

  // function debounce(func, wait, immediate) {
  //   var timeout;
  //   return function () {
  //     var context = this,
  //       args = arguments;
  //     var later = function () {
  //       timeout = null;
  //       if (!immediate) func.apply(context, args);
  //     };
  //     var callNow = immediate && !timeout;
  //     clearTimeout(timeout);
  //     timeout = setTimeout(later, wait);
  //     if (callNow) func.apply(context, args);
  //   };
  // }
})();
