(function () {
  var tabs = require('tabs');
  var container = document.querySelector('.tab-container')

  if (container) {
    tabs(container);

    let tabsMobile = document.querySelector('.tabs')
    let currentTab = document.querySelector('.tab-current')
    let tabLinks = document.querySelectorAll('.tab')
    if (window.innerWidth <= 992) {
      tabLinks.forEach(el => el.addEventListener('click', () => {
        if (!el.classList.contains('tab-current')) {
          currentTab.innerHTML = el.innerHTML;
          let hiddenTab = document.querySelector('.tab[data-active="active"]')
          console.log(el)
          el.setAttribute("data-active", "active")
          if (hiddenTab) {
            hiddenTab.setAttribute("data-active", "")
          }
        }
      }))

      tabsMobile.addEventListener('click', () => {
        tabsMobile.classList.toggle('opened')
        tabsMobile.classList.toggle('arrow-up')
      })
    }
  }
})()