(function () {
    const cuttedBlocks = document.querySelectorAll('.team-card__cutted-block')
    if (cuttedBlocks) {
        cuttedBlocks.forEach(item => {
            item.querySelector('a').addEventListener('click', function (e) {
                e.preventDefault();
                item.classList.toggle('full-text')
                if (item.classList.contains('full-text')) {
                    this.textContent = "Read less"
                } else {
                    this.textContent = 'Read more'
                }
            })
        })
    }
})()